import type {} from '@mui/lab/themeAugmentation';
import { createTheme, Theme } from '@mui/material/styles';
import type {} from '@mui/x-date-pickers-pro/themeAugmentation';

import { breakpoints } from './breakpoints';
import { components } from './components';
import { palette } from './palette';
import { typography } from './typography';

export const theme: Theme = createTheme({
  palette,
  typography,
  components,
  breakpoints,
});
