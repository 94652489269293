import { Components } from '@mui/material/styles/components';
import { Theme } from '@mui/material/styles/createTheme';

export const MuiSnackbarContent: Components<Omit<Theme, 'components'>>['MuiSnackbarContent'] = {
  styleOverrides: {
    root: {
      boxShadow: '0px 1px 3px 0px #0000004D',
    },
  },
};
