/* eslint-disable @typescript-eslint/naming-convention */
const success = {
  0: '#FFFFFF',
  50: '#EBF5EA',
  100: '#CEE5CC',
  200: '#AFD4AB',
  300: '#91C58A',
  400: '#7BB873',
  500: '#67AC5C',
  600: '#5D9D52',
  700: '#508B46',
  800: '#457B3C',
  900: '#305C28',
};

export default {
  success: {
    ...success,
    main: success[600],
  },
  'on-success': {
    main: success[0],
  },
  'success-container': {
    main: success[100],
  },
  'success-container-low': {
    main: success[50],
  },
  'on-success-container': {
    main: success[900],
  },
  'success-300': {
    main: success[300],
  },
};
