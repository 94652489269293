/* eslint-disable @typescript-eslint/naming-convention */
import { PaletteColorOptions } from '@mui/material';

const neutral: PaletteColorOptions = {
  10: '#FCFCFF',
  20: '#F9F9FC',
  40: '#F3F3F6',
  50: '#F0F0F4',
  60: '#EDEDF1',
  80: '#E8E8EB',
  100: '#E2E2E5',
  130: '#D9DADD',
  200: '#C6C6C9',
  300: '#AAABAE',
  400: '#909194',
  500: '#76777A',
  600: '#5D5E61',
  700: '#45474A',
  760: '#37393C',
  780: '#333537',
  800: '#303133',
  830: '#282A2D',
  880: '#1E2022',
  900: '#1A1C1E',
  940: '#111416',
  960: '#0C0E11',

  light: '#F9DAED', // 100
  main: '#897080', // 500
  dark: '#271623', // 900
};

export default neutral;
