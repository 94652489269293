/* eslint-disable @typescript-eslint/naming-convention */
const primaryPalette = {
  0: '#FFFFFF',
  10: '#FEFBFF',
  50: '#F0EFFF',
  100: '#DEE0FF',
  200: '#BCC3FA',
  300: '#A0A8DD',
  400: '#868EC1',
  500: '#6C74A6',
  600: '#535B8B',
  700: '#3C4372',
  800: '#252D5A',
  900: '#061744',
};

export default {
  primary: {
    ...primaryPalette,
    main: primaryPalette[600],
  },
  'primary-lowest': {
    main: primaryPalette[400],
  },
  'primary-low': {
    main: primaryPalette[500],
  },
  'on-primary': {
    main: primaryPalette[0],
  },
  'primary-container': {
    main: primaryPalette[100],
  },
  'primary-container-low': {
    main: primaryPalette[50],
  },
  'primary-container-high': {
    main: primaryPalette[200],
  },
  'primary-container-highest': {
    main: primaryPalette[300],
  },
  'on-primary-container': {
    main: primaryPalette[900],
  },
};
