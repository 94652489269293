/* eslint-disable @typescript-eslint/naming-convention */
import { Components } from '@mui/material/styles/components';
import { Theme } from '@mui/material/styles/createTheme';

export const MuiButton: Components<Omit<Theme, 'components'>>['MuiButton'] = {
  defaultProps: {
    sx: {
      borderRadius: '0.5rem',
      textTransform: 'none',
      boxShadow: 'none',
      minWidth: '2.25rem',
    },
  },
  styleOverrides: {
    root: {
      '& .MuiButton-startIcon': {
        marginRight: '0',
        marginLeft: '0',
      },
      '& .MuiButton-endIcon': {
        marginRight: '0',
        marginLeft: '0',
      },
    },
  },
};
