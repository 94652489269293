import { Components } from '@mui/material/styles/components';
import { Theme } from '@mui/material/styles/createTheme';

export const MuiTypography: Components<Omit<Theme, 'components'>>['MuiTypography'] = {
  styleOverrides: {
    root: {
      color: 'currentcolor',
    },
  },
};
