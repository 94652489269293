/* eslint-disable @typescript-eslint/naming-convention */
import { Components } from '@mui/material/styles/components';
import { Theme } from '@mui/material/styles/createTheme';

import { typography } from '../typography';

const dateCalendarStyles = {
  width: '22.5rem',
  maxHeight: '25.15rem',
  height: 'auto',
};

export const MuiPickersLayout: Components<Omit<Theme, 'components'>>['MuiPickersLayout'] = {
  styleOverrides: {
    root: {
      backgroundColor: 'var(--color-surf-container-high)',
      borderRadius: '1rem',
    },
  },
};

export const MuiPickersDay: Components<Omit<Theme, 'components'>>['MuiPickersDay'] = {
  styleOverrides: {
    root: {
      ...typography.body1,
      width: '2.75rem',
      height: '2.75rem',
      transform: 'none !important',
    },
  },
};

export const MuiDayCalendar: Components<Omit<Theme, 'components'>>['MuiDayCalendar'] = {
  styleOverrides: {
    weekDayLabel: {
      ...typography.body1,
      width: '2.75rem',
      height: '2.75rem',
    },
    weekContainer: {
      margin: '0',
    },
    slideTransition: {
      minHeight: '18.9rem !important',
    },
  },
};

export const MuiDateCalendar: Components<Omit<Theme, 'components'>>['MuiDateCalendar'] = {
  styleOverrides: {
    root: {
      ...dateCalendarStyles,
      '& .MuiPickersDay-root': {
        margin: '2px',
      },
    },
  },
};

export const MuiDateRangeCalendar: Components<Omit<Theme, 'components'>>['MuiDateRangeCalendar'] = {
  styleOverrides: {
    root: {
      ...dateCalendarStyles,
    },
    monthContainer: {
      width: '100%',
    },
  },
};

export const MuiMonthCalendar: Components<Omit<Theme, 'components'>>['MuiMonthCalendar'] = {
  styleOverrides: {
    root: {
      width: '100%',
    },
  },
};

export const MuiYearCalendar: Components<Omit<Theme, 'components'>>['MuiYearCalendar'] = {
  styleOverrides: {
    root: {
      width: '100%',
    },
  },
};

export const MuiDialogActions: Components<Omit<Theme, 'components'>>['MuiDialogActions'] = {
  styleOverrides: {
    root: {
      paddingTop: '0',
    },
  },
};

export const MuiPaper: Components<Omit<Theme, 'components'>>['MuiPaper'] = {
  styleOverrides: {
    root: {
      borderRadius: '1rem',
    },
  },
};
