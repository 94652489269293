/* eslint-disable @typescript-eslint/naming-convention */
import { Components } from '@mui/material/styles/components';
import { Theme } from '@mui/material/styles/createTheme';

export const MuiChip: Components<Omit<Theme, 'components'>>['MuiChip'] = {
  variants: [
    {
      props: { color: 'primary' },
      style: {
        backgroundColor: 'var(--color-primary-container)',
        color: 'var(--color-on-primary-container)',
      },
    },
    {
      props: { color: 'secondary' },
      style: {
        backgroundColor: 'var(--color-secondary-container)',
        color: 'var(--color-on-secondary-container)',
      },
    },
    {
      props: { color: 'error' },
      style: {
        backgroundColor: 'var(--color-error-container-low)',
        color: 'var(--color-on-error-container)',
        ':hover': {
          boxShadow: 'inset 0 0 0 1px var(--color-on-error-container)',
          backgroundColor: 'var(--color-error-container-low)',
        },
      },
    },
    {
      props: { color: 'info' },
      style: {
        backgroundColor: 'var(--color-info-container)',
        color: 'var(--color-on-info-container)',
      },
    },
    {
      props: { color: 'success' },
      style: {
        backgroundColor: 'var(--color-success-container)',
        color: 'var(--color-on-success-container)',
      },
    },
    {
      props: { color: 'warning' },
      style: {
        backgroundColor: 'var(--color-warning-container)',
        color: 'var(--color-on-warning-container)',
      },
    },
    {
      props: { color: 'default' },
      style: {
        backgroundColor: 'var(--color-surf-container)',
        color: 'var(--color-on-surf-variant)',
      },
    },
  ],
};
