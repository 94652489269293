import { Components } from '@mui/material/styles/components';
import { Theme } from '@mui/material/styles/createTheme';

export const MuiTabPanel: Components<Omit<Theme, 'components'>>['MuiTabPanel'] = {
  styleOverrides: {
    root: {
      padding: 0,
    },
  },
};
