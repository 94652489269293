/* eslint-disable @typescript-eslint/naming-convention */
const error = {
  0: '#FFFFFF',
  10: '#FFFBFF',
  50: '#FFEDEA',
  100: '#FFDAD6',
  200: '#FFB4AB',
  300: '#FF897D',
  400: '#FF5449',
  500: '#DE3730',
  600: '#BA1A1A',
  700: '#93000A',
  800: '#690005',
  900: '#410002',
};

export default {
  error: {
    ...error,
    main: error[600],
  },
  'on-error': {
    main: error[0],
  },
  'error-container': {
    main: error[100],
  },
  'error-container-low': {
    main: error[50],
  },
  'on-error-container': {
    main: error[900],
  },
};
