/* eslint-disable @typescript-eslint/naming-convention */
const secondaryPalette = {
  0: '#FFFFFF',
  10: '#FEFBFF',
  50: '#F0EFFF',
  100: '#E2E1F0',
  200: '#C5C5D4',
  300: '#AAAAB8',
  400: '#8F909E',
  500: '#757683',
  600: '#5D5D6A',
  700: '#454652',
  800: '#2E303B',
  900: '#191B26',
};

export default {
  secondary: {
    ...secondaryPalette,
    main: secondaryPalette[600],
  },
  'on-secondary': {
    main: secondaryPalette[100],
  },
  'secondary-container': {
    main: secondaryPalette[100],
  },
  'secondary-container-low': {
    main: secondaryPalette[50],
  },
  'secondary-container-high': {
    main: secondaryPalette[200],
  },
  'secondary-container-highest': {
    main: secondaryPalette[300],
  },
  'on-secondary-container': {
    main: secondaryPalette[900],
  },
};
