/* eslint-disable @typescript-eslint/naming-convention */
import { Components } from '@mui/material/styles/components';
import { Theme } from '@mui/material/styles/createTheme';

export const MuiStepper: Components<Omit<Theme, 'components'>>['MuiStepper'] = {
  styleOverrides: {
    root: {
      gap: '1rem',
    },
  },
};
