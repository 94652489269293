import { TypographyOptions } from '@mui/material/styles/createTypography';
import { Roboto } from 'next/font/google';

const roboto = Roboto({
  weight: ['400', '500', '700'],
  style: ['normal', 'italic'],
  subsets: ['latin'],
  display: 'swap',
});

export const typography: TypographyOptions = {
  fontFamily: roboto.style.fontFamily,
  displayLarge: {
    // Display Large
    fontWeight: 400,
    fontSize: '3.563rem',
    lineHeight: '4rem',
    letterSpacing: '-0.016rem',
  },
  displayMedium: {
    // Display Medium
    fontWeight: 400,
    fontSize: '2.813rem',
    lineHeight: '3.25rem',
    letterSpacing: 0,
  },
  displaySmall: {
    // Display Small
    fontWeight: 400,
    fontSize: '2.25rem',
    lineHeight: '2.75rem',
    letterSpacing: 0,
  },
  h1: {
    // Heading Large
    fontWeight: 400,
    fontSize: '2rem',
    lineHeight: '2.5rem',
    letterSpacing: 0,
  },
  h3: {
    // Heading Medium
    fontWeight: 400,
    fontSize: '1.75rem',
    lineHeight: '2.25rem',
    letterSpacing: 0,
  },
  h6: {
    // Heading Small
    fontWeight: 400,
    fontSize: '1.5rem',
    lineHeight: '2rem',
    letterSpacing: 0,
  },
  subtitle1: {
    // Title Large
    fontWeight: 400,
    fontSize: '1.375rem',
    lineHeight: '1.75rem',
    letterSpacing: 0,
  },
  subtitle2: {
    // Title Medium
    fontWeight: 500,
    fontSize: '1rem',
    lineHeight: '1.5rem',
    letterSpacing: '0.009rem',
  },
  subtitle3: {
    // Title Small
    fontWeight: 500,
    fontSize: '0.875rem',
    lineHeight: '1.25rem',
    letterSpacing: '0.006rem',
  },
  labelLarge: {
    // Label Large
    fontWeight: 500,
    fontSize: '0.875rem',
    lineHeight: '1.25rem',
    letterSpacing: '0.006rem',
  },
  labelLargeProminent: {
    // Label Large Prominent
    fontWeight: 600,
    fontSize: '0.875rem',
    lineHeight: '1.25rem',
    letterSpacing: '0.006rem',
  },
  labelMedium: {
    // Label Medium
    fontWeight: 500,
    fontSize: '0.75rem',
    lineHeight: '1rem',
    letterSpacing: '0.031rem',
  },
  labelSmall: {
    // Label Small
    fontWeight: 500,
    fontSize: '0.688rem',
    lineHeight: '1rem',
    letterSpacing: '0.031rem',
  },
  body1: {
    // Body Large
    fontWeight: 400,
    fontSize: '1rem',
    lineHeight: '1.5rem',
    letterSpacing: '0.031rem',
  },
  body2: {
    // Body Medium
    fontWeight: 400,
    fontSize: '0.875rem',
    lineHeight: '1.25rem',
    letterSpacing: '0.016rem',
    color: 'var(--color-on-surf-variant)',
  },
  body3: {
    // Body Small
    fontWeight: 400,
    fontSize: '0.75rem',
    lineHeight: '1rem',
    letterSpacing: '0.025rem',
  },
};
