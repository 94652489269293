/* eslint-disable @typescript-eslint/naming-convention */
import { Components } from '@mui/material/styles/components';
import { Theme } from '@mui/material/styles/createTheme';

import { typography } from '../typography';

export const MuiTab: Components<Omit<Theme, 'components'>>['MuiTab'] = {
  styleOverrides: {
    root: {
      minWidth: '2.5rem',
      textTransform: 'capitalize',
      ...typography.labelLarge,
      color: 'var(--color-on-surf-variant)',
      '&.MuiTab-textColorSecondary.Mui-selected': {
        color: 'var(--color-on-surf)',
      },
      '&.MuiTab-textColorSecondary.Mui-disabled': {
        color: 'var(--color-on-surf-variant)',
      },
    },
  },
};
