/* eslint-disable @typescript-eslint/naming-convention */
const tertiary = {
  0: '#FFFFFF',
  10: '#FFFBFF',
  50: '#FFECF6',
  100: '#F9DAED',
  200: '#DCBED1',
  300: '#BFA3B5',
  400: '#A4899A',
  500: '#897080',
  600: '#6F5767',
  700: '#56404F',
  800: '#33202E',
  900: '#271623',
};

export default {
  tertiary: {
    ...tertiary,
    main: tertiary[600],
  },
  'on-tertiary': {
    main: tertiary[0],
  },
  'tertiary-container': {
    main: tertiary[100],
  },
  'tertiary-container-low': {
    main: tertiary[50],
  },
  'tertiary-container-high': {
    main: tertiary[200],
  },
  'tertiary-container-highest': {
    main: tertiary[300],
  },
  'on-tertiary-container': {
    main: tertiary[900],
  },
};
