import { Components } from '@mui/material/styles/components';
import { Theme } from '@mui/material/styles/createTheme';
import { MuiButton } from '@th-common/theme/components/MuiButton';
import { MuiCheckbox, MuiFormControlLabel } from '@th-common/theme/components/MuiCheckbox';
import {
  MuiDateCalendar,
  MuiDateRangeCalendar,
  MuiDayCalendar,
  MuiDialogActions,
  MuiMonthCalendar,
  MuiPaper,
  MuiPickersDay,
  MuiPickersLayout,
  MuiYearCalendar,
} from '@th-common/theme/components/MuiDatePicker';
import { MuiIconButton } from '@th-common/theme/components/MuiIconButton';
import { MuiMenu } from '@th-common/theme/components/MuiMenu';
import { MuiSelect } from '@th-common/theme/components/MuiSelect';
import { MuiSnackbarContent } from '@th-common/theme/components/MuiSnackbarContent';
import { MuiTab } from '@th-common/theme/components/MuiTab';
import { MuiTabPanel } from '@th-common/theme/components/MuiTabPanel';
import {
  MuiFilledInput,
  MuiFormHelperText,
  MuiFormLabel,
  MuiOutlinedInput,
  MuiTextField,
} from '@th-common/theme/components/MuiTextField';
import { MuiToggleButton, MuiToggleButtonGroup } from '@th-common/theme/components/MuiToggle';

import { MuiChip } from './MuiChip';
import { MuiStepper } from './MuiStepper';
import { MuiTableCell, MuiTableContainer, MuiTableRow } from './MuiTable';
import { MuiTypography } from './MuiTypography';

export const components: Components<Omit<Theme, 'components'>> = {
  MuiTextField,
  MuiButton,
  MuiCheckbox,
  MuiMenu,
  MuiIconButton,
  MuiSnackbarContent,
  MuiTab,
  MuiTabPanel,
  MuiOutlinedInput,
  MuiPickersLayout,
  MuiPaper,
  MuiSelect,
  MuiFilledInput,
  MuiFormHelperText,
  MuiToggleButton,
  MuiToggleButtonGroup,
  MuiPickersDay,
  MuiDayCalendar,
  MuiDateCalendar,
  MuiDialogActions,
  MuiDateRangeCalendar,
  MuiMonthCalendar,
  MuiYearCalendar,
  MuiFormLabel,
  MuiTableContainer,
  MuiTableCell,
  MuiChip,
  MuiTableRow,
  MuiStepper,
  MuiFormControlLabel,
  MuiTypography,
};
