/* eslint-disable @typescript-eslint/naming-convention */
const neutralVariantPalette = {
  0: '#FEFBFF',
  10: '#FEFBFF',
  20: '#FBF8FF',
  40: '#F5F2FA',
  50: '#F2F0F6',
  60: '#EFEDF4',
  80: '#E9E7EF',
  100: '#E4E1E9',
  130: '#D7D5DB',
  200: '#C7C5CC',
  300: '#ACAAB1',
  400: '#919096',
  500: '#77767C',
  600: '#5E5E63',
  700: '#46464C',
  760: '#3B3B40',
  780: '#36363B',
  800: '#303035',
  830: '#29292E',
  880: '#1F1F24',
  900: '#1B1B20',
  940: '#101115',
  960: '#0E0F12',
};

export default {
  surf: {
    ...neutralVariantPalette,
    main: neutralVariantPalette[20],
  },
  'on-surf': {
    main: neutralVariantPalette[900],
  },
  'surf-variant': {
    main: neutralVariantPalette[130],
  },
  'on-surf-variant': {
    main: neutralVariantPalette[700],
  },
  'surf-container-lowest': {
    main: neutralVariantPalette[0],
  },
  'surf-container-low': {
    main: neutralVariantPalette[40],
  },
  'surf-container': {
    main: neutralVariantPalette[60],
  },
  'surf-container-high': {
    main: neutralVariantPalette[80],
  },
  'surf-container-highest': {
    main: neutralVariantPalette[100],
  },
  outline: {
    main: neutralVariantPalette[500],
  },
  'outline-variant': {
    main: neutralVariantPalette[200],
  },
  'surf-inverse': {
    main: neutralVariantPalette[800],
  },
  'on-surf-inverse': {
    main: neutralVariantPalette[50],
  },
};
