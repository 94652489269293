/* eslint-disable @typescript-eslint/naming-convention */
import { Components } from '@mui/material/styles/components';
import { Theme } from '@mui/material/styles/createTheme';

import { typography } from '../typography';

export const MuiTableContainer: Components<Omit<Theme, 'components'>>['MuiTableContainer'] = {
  styleOverrides: {
    root: {
      borderRadius: 0,
      backgroundColor: 'transparent',
      boxShadow: 'none',
    },
  },
};

export const MuiTableRow: Components<Omit<Theme, 'components'>>['MuiTableRow'] = {
  styleOverrides: {
    root: {
      '&.MuiTableRow-hover:hover': {
        cursor: 'pointer',
      },
    },
  },
};

export const MuiTableCell: Components<Omit<Theme, 'components'>>['MuiTableCell'] = {
  styleOverrides: {
    root: {
      color: 'var(--color-on-surf-variant)',
      borderColor: 'var(--color-outline-variant)',
      ...typography.body3,
    },
    head: {
      ...typography.labelMedium,
      backgroundColor: 'var(--color-surf-container-highest)',
    },
  },
};
