/* eslint-disable @typescript-eslint/naming-convention */
const warning = {
  0: '#FFFFFF',
  50: '#FEF8E4',
  100: '#FCECBA',
  200: '#FBE08F',
  300: '#F9D568',
  400: '#F8CB50',
  500: '#F6C244',
  600: '#F5B53F',
  700: '#F3A33A',
  800: '#F19436',
  900: '#EE772F',
};

export default {
  warning: {
    ...warning,
    main: warning[600],
  },
  'on-warning': {
    main: warning[0],
  },
  'warning-container': {
    main: warning[100],
  },
  'warning-container-low': {
    main: warning[50],
  },
  'on-warning-container': {
    main: warning[900],
  },
};
